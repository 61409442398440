<template>
  <div>
    <vx-card>
      <vs-table :data="notifications" class="mt-5 p-2" max-items="20">
        <template slot="thead">
          <vs-th> Id </vs-th>
          <vs-th> Notification Url </vs-th>
          <vs-th> Auth required </vs-th>
          <vs-th> Payload </vs-th>
          <vs-th> Type </vs-th>
          <vs-th> Notification type </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr">
              {{ tr.paymentRequestId || tr.onboardingRequestId || "-" }}
            </vs-td>
            <vs-td :data="tr.notificationUrl">
              {{ tr.notificationUrl || "" }}
            </vs-td>
              <vs-td :data="tr.authRequired">
              {{ tr.authRequired }}
            </vs-td>
            <vs-td :data="tr.payload">
              {{ tr.payload }}
            </vs-td>
            <vs-td :data="tr.type">
              {{ tr.type | capitalize}}
            </vs-td>
            <vs-td :data="tr.notificationType">
              {{ tr.notificationType || "Payment request" }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

    </vx-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      notifications: []
    };
  },
  methods: {
    ...mapActions("notificationVerify", ["fetchList"]),
    async getList() {
      await this.fetchList()
        .then(result => {
          this.notifications = result.data.data;
        })
        .catch(err => {});
    }
  },
  mounted() {

    this.getList();
  }
};
</script>
